import React from 'react'
import { Link } from 'gatsby'
import SVGIcon from "../SvgCurve"

export default function AccountOption(props) {
    const { icon, text, link } = props
    return (
        <Link to={link} className="AccountOption" activeClassName="AccountOption-selected">
            <SVGIcon icon={icon} />
            {text}
        </Link>
    )
}
