import React from 'react'
import Layout from "../../components/Layout"
import AccountLayout from "../../components/Account/AccountLayout"
import SvgIcon from "../../components/SvgCurve"
import FavoriteProduct from "../../components/FavoritesProduct";
import { favoriteList } from "../../data/favorite-list";

export default function Favorites() {

    const renderList = () => {
        return favoriteList&&favoriteList.map((item) => {
            return(
                <div className="col-12 col-md-6">
                    <FavoriteProduct image={item.image} price={item.price} name={item.name} />
                </div>
            )
        })
    }

    return (
        <Layout>
            <AccountLayout>
                <div className="Favorites">
                    <span className="Favorites-title"><SvgIcon className="Favorites-icon" icon="heart" />Mis favoritos</span>
                    <button className="Favorites-btn">Añadir todos a la cesta</button>
                    <div className="Favorites-content">
                        <div className="row">
                            {renderList()}
                        </div>
                    </div>
                </div>
            </AccountLayout>
        </Layout>
    )
}
