import image from "../images/FavoriteProduct/product1.jpg";
import image2 from "../images/FavoriteProduct/product2.jpg";

export const favoriteList = [
    {
        image: image,
        price:"110.00€",
        name:"REPRODUCCIÓN DEL CONJUNTO ESCULTÓRICO DE LA CRUZ GLORIOSA"
    },
    {
        image: image2,
        price:"21.00€",
        name:"ANILLO STA. Mª DEL PI ROSETON KIMU"
    },
]