import React from 'react'
import AccountOption from './AccountOption'
import AccountOptions from './AccountOptions'
import AccountUser from "./AccountUser"

export default function AccountLayout(props) {
    const { children } = props
    return (
        <div className="AccountLayout">
            <div className="AccountLayout-container">
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <AccountUser name="José Luis" />
                        <AccountOptions>
                            <AccountOption icon="bag" text="Mis pedidos" link="/privado/pedidos" />
                            <AccountOption icon="heart" text="Mis favoritos" link="/privado/favoritos" />
                        </AccountOptions>
                        <AccountOptions>
                            <AccountOption icon="cardId" text="Mis datos" link="/privado/datos" />
                            <AccountOption icon="addresses" text="Mis direcciones" link="/privado/direcciones"/>
                            <AccountOption icon="keyIcon" text="Cambiar contraseña" link="/privado/password"/>
                        </AccountOptions>
                        <AccountOptions>
                            <AccountOption icon="infoIcon" text="Necesitas ayuda?" link="/privado/ayuda" />
                            <AccountOption icon="logout" text="Cerrar sesión" link="/acceder"/>
                        </AccountOptions>
                    </div>
                    <div className="col-12 col-lg-8">{children}</div>
                </div>
            </div>
        </div>
    )
}
