import React from 'react'
import Proptypes from 'prop-types'
import trashIcon from "../../images/FavoriteProduct/icon-trash.png"

export default function FavoriteProduct(props) {
    const { image, price, name } = props;

    return (
        <div className="FavoriteProduct">
            <div className="FavoriteProduct-image" style={{ backgroundImage: `url(${image})` }}>
            {/* eslint-disable-next-line*/ }
                <button className="FavoriteProduct-remove" style={{ backgroundImage: `url(${trashIcon})` }} />
            </div>
            <span className="FavoriteProduct-price">{price}</span>
            <span className="FavoriteProduct-name">{name}</span>
            <button className="FavoriteProduct-btn">Comprar</button>
        </div>
    )
}

FavoriteProduct.defaultProps = {
    image: "",
    price: "",
    name: ""
}

FavoriteProduct.propTypes = {
    image: Proptypes.string,
    price: Proptypes.string,
    name: Proptypes.string
}